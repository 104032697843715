body {
  margin: 0;
  font-family:'Ubuntu', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Helvetica Neue', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:100%;
  color:#333d54;
  overflow-x:hidden;
}
.hidden, .-sm{
  display:none;
}
.-nsm{
  display: normal;
}
/*MAIN*/
.relative{
  position:relative;
}
.absolute{
  position:absolute;
}
._up{
  color:#16c784;
}
._down{
  color:#ea3943;
}
.lb, fieldset{
  background-color:#fff;
}
.mb{
  background-color:#eff2f5;
}
.m2b{
  background-color:#D7D9DC;
}
.db{
  background-color:#333d54;
}
.banner{
  position:relative;
  margin:0;
  padding:10px;
  z-index:2;
  border-bottom:1px solid #eff2f5;
}
.sub-banner{
  border-bottom:1px solid #eff2f5;
}
.sub-banner ul, .help_panel ul{
  margin:0 1em;
}
.sub-banner li, .help_panel li{
  margin:10px 15px;
  font-size:0.75rem;
}
.sub-banner img{
  height:16px;
  width:16px;
}
.sub-banner li.sb_li_mn{
  white-space:nowrap;
  overflow:auto;
  width:140px;
}
.unit {
  font-size: 0.5rem;
}

/* Details */
.sub-banner li .details{
  display:none;
  left:0;
}
.help_panel.details{
  right:0;
  width:300px;
}
.details{
  top:calc(100%);
  background-color: #fff;
  padding:0.2em;
  min-width:300px;
  z-index:2;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.details ul{
  margin:0em;
}
.details li{
  padding:5px 0;
  border-bottom:1px solid #eff2f5;
}
.details span{
  display:inline-block;
  width:50%;
}
.sub-banner li:hover .details{
  display:block;
  background-color: #fff;
  z-index:2;
}

/*landing container*/
.landing-container{
  background: linear-gradient(#f8fafd, #fff);
  min-height:calc(100vh - 80px);
  position:relative;
}
.landing-container main{
  min-height:50vh;
}
.landing-container ul{
  padding:0;
  margin:0;
}
.landing-container .lul li{
  margin: 10px 15px;
}
.landing_p{
  color:#A3A7B1;
}
.line_breaker{
  margin:3em auto;
}
.landing-note{
  font-size:0.8rem;
  margin:5px;
}
.landing-container img{
  height:7rem;
}

/* attribution */
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: underline;
}

/*main container*/
.main-container{
  background: linear-gradient(#f8fafd, #fff);
  min-height:90vh;
}
.left{
  text-align:left;
}
.right{
  text-align:right;
}
.center{
  text-align:center;
}
h1{
  margin:10px;
  max-width:13em;
  font-family:Montserrat, sans-serif;
}
h1:hover,
button:hover,
.hover:hover{
  cursor:pointer;
}
ul{
  list-style-type:none;
  margin:0;
  padding:0;
}
li{
  margin:0;
  padding:0;
}
.top{
  position:relative;
}
h2{
  font-family:Montserrat, sans-serif;
  margin:0 15px;
  font-size:4rem;
  position:relative;
  top:0;
}
p{
  font-weight:600;
  color:#58667e;
}
.g_container{
  margin:-12px -0.5em;
  width:calc(100% + 24px);
  box-sizing:border-box;
}
.g_item{
  margin:12px 1em;
  max-width:100%;
  flex-basis:100%;
  flex-grow:0;
}

/* Block */
h3{
  font-size:4rem;
  font-weight:200;
  margin:0;
}
.block_header{
  margin:2em;
}
.block_header p{
  margin:5px 15px;
}
/*footer*/
footer{
  font-size:0.9375rem;
  line-height:1.6em;
  border-top:1px solid #eff2f5;;
}
footer > div {
  padding-left: 2em;
  padding-right: 50vw;
}

/*Secondary*/
.fabdiv{
  position:fixed;
  z-index:3;
  bottom:15px;
}
.fabdiv.left{
  left:15px;
}
.fabdiv.right{
  right:15px;
}
.line-chart-wrapper.small{
  height:4em;
  margin:auto;
}
.line-chart-wrapper.big, .scatter-chart-wrapper.big{
  height:577px;
  min-height:300px;
  margin:0;
}
.line-chart-wrapper, .scatter-chart-wrapper{
  width:100%;
}
.chart-wrapper{
  height:430px;
  max-width:50vw;
  margin:auto;
}
tspan{
  font-size:0.55rem;
}
.pieLegend ul{
  list-style-type:none;
  margin:0;
  padding:0;
}
.pieLegend ul li{
  margin:5px 15px;
  padding:7px 15px 10px 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  font-size:1rem;
}

/* tooltip */
.tooltip p{
  margin:10px;
}
.tooltip .n_big{
  font-size:2rem;
}

/* flex */
.flex{
  display:flex;
}
.fcolumn{
  flex-direction:column;
}
.fcenter{
  justify-content:center;
}
.fvcent{
  align-items:center;
}
.fspev{
  justify-content:space-evenly;
}
.fspbe{
  justify-content:space-between;
}
.fstart{
  justify-content:flex-start;
}
.fend{
  justify-content:flex-end;
}
.wrap{
  flex-wrap:wrap;
}
.fgrow1{
  flex-grow:1;
}
.fbreak{
  height:0;
  flex-basis:100%;
}
.flex .g_item{
  max-width:40%;
}

/*button*/
nav.sim-nav{
  padding:12px 17px 0 17px;
}
.navbtn{
  margin:5px 15px;
}
.secondary .btn-outlined{
  font-size:0.9rem;
}
.btn-outlined{
  display:inline-block;
  padding:0.5em 1.4em;
  border-radius:0.12em;
  max-height:2.6em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:inherit;
  font-size:1rem;
  font-weight:300;
  text-align:center;
  transition: all 0.2s;
  background-color:transparent;
}
.btn-outlined.light{
  color:#333d54;
  border: solid 1px #eff2f5;
  background-color:#fff;
}
.btn-outlined.light:hover,
.btn-outlined.light:focus{
  background-color:#f6f9fc;
}
.btn-outlined.dark{
  color:#f6f9fc;
  background-color:#333d54;
  border: solid 1px #333d54;
}
.btn-outlined.med,
.btn-outlined.medl:hover,
.btn-outlined.medl:focus{
  color:#333d54;
  border: none;
  background-color:#D7D9DC;
}
.btn-outlined.medl{
  color:#333d54;
  border: none;
  background-color:transparent;
}

/*Table*/
td{
  max-width:150px;
}
.table-content{
  padding:0 2em 2em 2em;
  margin: 0;
  position:relative;
}
.table-overflow{
  max-height:90vh;
  overflow:auto;
}
table{
  width:100%;
  position:relative;
}
table.tbl_main{
  border-collapse:collapse;
  background-color:#fff;
  min-width: 600px;
}
.tbl_main tbody{
  position:relative;
  display:block;
  width:100%;
}
.tbl_main tr{
  display:inline-table;
  width:100%;
}
.tbl_main tbody tr{
  border-bottom:1px solid #eff2f5;
  position:absolute;
  left:0;
  right:0;
  height:87px;
}
.tbl_main tbody tr:hover{
  cursor:pointer;
  background-color:#eff2f5;
}
.tbl_main thead{
  position:sticky;
  display:block;
  width:100%;
  top:0;
  background-color:#fff;
  z-index:1;
}
table tr th{
  color:#5b6383;
  font-weight:700;
  padding:10px 15px;
  font-size:0.8rem;
}
.tbl_sec th{
  background-color:#F8F9FB;
}
.tbl_sec .strong{
  font-weight:800;
  background-color:#DFE0E1;
}
.tbl_sec .notice{
  background-color:#EDEDEE;
}
table tr td{
  font-size:0.875rem;
  position:relative;
}
.tbl_main tr td{
  padding:15px;
  font-weight:700;
}
.tbl_sec tr td{
  padding: 5px 15px;
  border:1px solid #eff2f5;
}

/*Modal*/
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: #ffffff;
  border: 2px solid #333d54;
  padding: 2rem;
}
.modal-container h4 {
  margin: 0;
  font-size: 2rem;
}

@media only screen and (max-width: 1300px){
  .line-chart-wrapper.small{
    max-width: 100px;
  }
}
@media only screen and (max-width: 1200px){
  .tooltip .n_big{
    font-size:1.6rem;
  }
  .tooltip .n_small{
    font-size:0.9rem;
  }
  table tr td, table tr th{
    font-size:0.8rem;
  }
}
@media only screen and (max-width: 930px){
  .flex .g_item{
    max-width:100%;
  }
  h2{
    font-size:3rem;
  }
  .-sm{
    display:inline;
  }
  .-nsm{
    display:none;
  }
  .line-chart-wrapper.big, .scatter-chart-wrapper.big{
    height:100vh;
    max-height: 500px;
    min-height: auto;
  }
  table.tbl_main tr td:nth-child(1), table.tbl_main tr th:nth-child(1){
    background-color:#fff;
    position:sticky;
    top:0;
    left:0;
    z-index:1;
  }
  .tbl_main tr td, .tbl_main tr th{
    padding: 2px;
  }
  table tr td, table tr th{
    font-size:0.7rem;
  }
}
@media only screen and (max-width: 900px){
  .tooltip .n_big{
    font-size:1rem;
  }
  .tooltip .n_small{
    font-size:0.8rem;
  }
}
@media only screen and (max-width: 812px) {
  h1{
    font-size:1.6rem;
  }
  .chart-wrapper{
    height:200px;
  }
  .line-chart-wrapper.small{
    height: 3em;
    max-width: 70px;
  }
  .g_container{
    margin:0;
    width:100%;
  }
  .g_item{
    margin:0;
  }
  .line-chart-wrapper, .scatter-chart-wrapper{
    max-width:100%;
    position:relative;
  }
  nav.sim-nav{
    padding:12px 27px;
  }
  .navbtn{
    margin:5px;
  }
  .btn-outlined{
    padding:0.5em;
    font-size:0.95rem;
  }
  td{
    max-width:100%;
  }
  .landing-container main{
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 400px) {
  .break-table-sm td, .break-table-sm th{
    display:block;
  }
  .btn-outlined, .secondary .btn-outlined{
    padding:0.5em;
    font-size:0.8rem;
  }
  .tooltip .n_big{
    font-size:0.8rem;
  }
  .tooltip .n_small{
    font-size:0.6rem;
  }
  h2{
    font-size:2rem;
  }
  .sub-banner li.sb_li_mn{
    width:auto;
  }
}



